import { SERVERURL } from '../config/VARS';
import { useHistory, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import infludataLogo from './infludata-logo.png'; // Make sure to import the logo
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ forceRefresh: true });




function Login() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState(''); 

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    try {
      let newBody = [];
      for (var property in formData) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(formData[property]);
        newBody.push(encodedKey + "=" + encodedValue);
      }
      newBody.push('method=chargebee')
      newBody = newBody.join("&");
      console.log(newBody);
      const response = await fetch(`${SERVERURL}profile/authenticate`, {
          method: 'post',
          body: newBody,
          credentials: 'include',
          headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'accept': '*/*',
          },
          //mode: 'no-cors'
        });
        // handle the response here
        console.log(response)
        if(response.ok){
          //console.log('cookies', cookies)
          
          //const setCookies = response.headers.get('set-cookie');
          //console.log('set', setCookies)

            if (true) {
                // Set the cookies
                //console.log('sookie is set')
                //document.cookie = setCookies;
                history.push("/hello");
                console.log('You are authenticated!')    
            } else {
              console.log('Cookies NOT found')
            }
        }else{
          if(response.status === 401){ // check for status code 401
            setError('Password incorrect. Please try again.'); // update the error state
          }else{
            console.log('Authentication failed!')
          }
        }
        setIsLoading(false)
    } catch (error) {
        console.error('Error:', error);
    }
};
  return (
    <div className="login-container">
    {isLoading && (
      <div className="loading-overlay">
        <Spinner animation="border" />
      </div>
    )}
    <header>
      <img src={infludataLogo} alt="Infludata Logo" className="infludata-logo" />
      <h1 className="header-title">influData Creator Management</h1>
    </header>
      <Form className="login-form" onSubmit={handleSubmit}>
        <h2>Login</h2>
        <Form.Group className="form-group" controlId="formBasicEmail">
          <Form.Label>Username</Form.Label>
          <Form.Control className="form-control" type="text" placeholder="Enter username"  onChange={(e)=>setFormData({...formData,email:e.target.value})}/>
        </Form.Group>

        <Form.Group className="form-group" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control className="form-control" type="password" placeholder="Password" onChange={(e)=>setFormData({...formData,password:e.target.value})} />
        </Form.Group>
        {error && <div className="error-message">{error}</div>}
        <Button className="btn" variant="primary" type="submit">
          Login
        </Button>
      </Form>
    </div>
  );
}



export default Login